import { DateISOString } from './misc';
import { AnonymousUserObject, BasicUserObject } from './UserObject';
import RoleCategoryObject from './RoleCategory';
import { MissionApplicationBasicObject } from './MissionApplicationObject';
import { UserTalentSkillAssignment } from './TalentCategories';
import TimezoneObject, { UtcOffsetRange } from './TimezoneObject';
import { WorkingHoursMissionRoleSchema } from './WorkingHoursObject';
import { TalentIndustry } from './TalentIndustry';
import { RoleOptimizationRequest } from './RoleOptimizationRequest';

export type MissionRoleId = string;
export type MissionRoleQuestionId = string;

export enum MissionRoleStatus {
  // Order determines display order in AdminNotes Mission's Tab
  Open = 'Open',
  Active = 'Active',
  ScheduledToEnd = 'ScheduledToEnd',
  Ended = 'Ended',
  Canceled = 'Canceled',
}

export type MissionRoleSkill = Omit<
  UserTalentSkillAssignment,
  'yearsOfExperience'
>;

export type MissionRoleRequiredSkill = MissionRoleSkill &
  Required<Pick<MissionRoleSkill, 'rating'>>;

export interface ClientRoleQuestion {
  qid: string;
  text: string;
  isRequired?: boolean;
  isVisible?: boolean;
}

export interface BasicMissionRole {
  rid: MissionRoleId;
  category: RoleCategoryObject;
  headline: string;
  locations?: string[];
  status: MissionRoleStatus;
  recommended?: boolean | null;
  user: AnonymousUserObject | BasicUserObject | null;
  availability?: {
    weeklyHoursAvailable: number;
    date?: DateISOString;
    scheduledEndDate?: DateISOString;
  };
  customQuestions?: ClientRoleQuestion[];
  timezone?: TimezoneObject;
  workingHours?: WorkingHoursMissionRoleSchema;
  preferredSkills?: MissionRoleSkill[];
  requiredSkills?: MissionRoleRequiredSkill[];
  lookingForApplications?: boolean | null;
  hourlyRate?: number;
  monthlyRate?: number;
  userAssignedAt?: DateISOString;
  /** @todo: remove visibility when the reachout is deprecated **/
  visibility?: MissionRoleVisibility;
  builderRateMin?: number;
  builderRateMax?: number;
  showRateRangeToBuilders?: boolean;
  builderMonthlyRateMin?: number;
  builderMonthlyRateMax?: number;
  collectMonthlyRate?: boolean;
  isFullTimeRetainer?: boolean;
}

export default interface MissionRole extends BasicMissionRole {
  application?: MissionApplicationBasicObject;

  // will return only for the current user
  hourlyRate?: number;
  marginVAT?: number;

  // will return only for managers and admins
  clientHourlyRate?: number;
  clientMonthlyRate?: number;
  utcOffsetRange?: UtcOffsetRange;
  lookingForApplications?: boolean | null;
}

export interface MissionAdminRole extends MissionRole {
  hourlyRate?: number;
  marginVAT?: number;
  utcOffsetRange?: UtcOffsetRange;
  automatedStatusesDisabled?: boolean;
  readyForReview?: boolean;
  lastOptimizationRequest?: RoleOptimizationRequest;
  margin?: number | null;
  isNiche?: boolean;
}

export interface MissionInvoiceRole extends BasicMissionRole {
  user: BasicUserObject;
  hourlyRate: number;
}

export interface MissionRoleVisibility {
  visibilityStatus: MissionRoleVisibilityStatus;
}

export enum MissionRoleVisibilityStatus {
  OnlyAdmin = 'OnlyAdmin',
  All = 'All',
}

interface RecommendationCriteria {
  industries?: TalentIndustry[];
  skills?: MissionRoleSkill[];
}

export interface RecommendedRole extends BasicMissionRole {
  recommended?: boolean | null;
  matches?: RecommendationCriteria;
}

export type MissionListRole = Pick<
  BasicMissionRole,
  | 'rid'
  | 'category'
  | 'locations'
  | 'availability'
  | 'lookingForApplications'
  | 'timezone'
  | 'workingHours'
>;

export interface MissionListItem {
  // Basic identification
  mid: string;
  title: string;

  // Company info
  companyName: string;
  logoURL?: string;

  // Mission details
  description: string;

  // All filterable data
  roles?: MissionListRole[];
  industries: TalentIndustry[];
  locations: string[];
  skills: MissionRoleSkill[];
  availability?: {
    weeklyHoursAvailable: number;
    timezone?: TimezoneObject;
    workingHours?: WorkingHoursMissionRoleSchema;
  };

  // Status flags
  isActivelyRecruiting: boolean;
  lookingForBuilders: boolean;
  isHidden?: boolean;
}
