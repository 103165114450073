import { DateISOString } from './misc';
import { MissionRoleId } from './MissionRole';
import { InvoiceAddress } from './InvoiceObject';
import { BasicUserObject } from './UserObject';
import { MissionId } from './MissionObject';

export type ContractId = string; // sid

export enum ContractType {
  TermsOfService = 'TermsOfService',
  MissionAgreement = 'MissionAgreement',
}

export enum ContractStatus {
  Created = 'Created',
  Completed = 'Completed',
}

export const ContractTitles: Record<ContractType, string> = {
  [ContractType.MissionAgreement]: 'Service Agreement',
  [ContractType.TermsOfService]: 'A.Team Platform Agreement',
};

export const ClientToSUrl =
  'https://cdn.a.team/contracts/tos/ateams-client-terms-of-service-agreement.pdf';

export interface BasicContractObject {
  sid: ContractId;
  type: ContractType;
  mission?: {
    mid: MissionId;
  };
  parties: BasicContractPartyObject[];
  status: ContractStatus;
  createdAt: DateISOString;
  signedAt?: DateISOString;
  deletedAt?: DateISOString;
  downloadURL: string;
  // `true` for custom PDF attachments
  custom?: boolean;
  rid?: MissionRoleId;
}

export enum ContractPartyType {
  BillingCustomer = 'BillingCustomer',
  MissionRole = 'MissionRole',
}

export interface BasicContractPartyObject {
  type: ContractPartyType;
  user?: BasicUserObject;
  signedAt?: DateISOString;
  ip?: string;
}

export interface ContractPartyObject extends BasicContractPartyObject {
  rid?: MissionRoleId;
  address: InvoiceAddress;
}

export default interface ContractObject extends BasicContractObject {
  parties: ContractPartyObject[];
}
