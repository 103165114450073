export enum UserReviewStatus {
  Pending = 'pending',
  Completed = 'completed',
}
export type StarRating = 1 | 2 | 3 | 4 | 5;

export interface UserReviewObject {
  id: string;
  publicFeedback?: string;
  role: string;
  industry?: string;
  period: string;
  companyLogo: string;
  companyName: string;
  visible?: boolean;
  // only for admins
  starRating?: StarRating;
  missionId?: string;
  missionTitle?: string;
  clientName?: string;
  privateFeedback?: string;
  wouldRecommend?: boolean;
  endedRoleReasons?: RoleEndedReason[];
  createdAt?: string;
  updatedAt?: string;
}

export interface ReviewEmailStatus {
  initial?: Date;
  friendly?: Date;
  lastChance?: Date;
}

export enum RoleEndedReason {
  WorkCompleted = 'WorkCompleted',
  RoleFit = 'RoleFit',
  TeamFit = 'TeamFit',
  Budget = 'Budget',
  Expensive = 'Expensive',
  Quality = 'Quality',
  Communication = 'Communication',
  Phase = 'Phase',
  Talent = 'Talent',
  Closing = 'Closing',
  FullTime = 'FullTime',
  OtherTalent = 'OtherTalent',
  Freeze = 'Freeze',
  Trial = 'Trial',
  Builder = 'Builder',
}

export const RoleEndedReasonLabel = {
  [RoleEndedReason.WorkCompleted]: 'The work has been completed',
  [RoleEndedReason.RoleFit]: 'The builder was not the right fit for the role',
  [RoleEndedReason.TeamFit]: 'The builder was not the right fit for our team',
  [RoleEndedReason.Budget]: 'Budget constraints',
  [RoleEndedReason.Expensive]: 'The builder is too expensive',
  [RoleEndedReason.Quality]:
    'Inadequate work quality or output from the builder',
  [RoleEndedReason.Communication]:
    'Interaction or communication issues with the builder',
  [RoleEndedReason.Phase]: 'We’re not ready to start the next phase',
  [RoleEndedReason.Talent]:
    'Our company/department is no longer working with fractional talent',
  [RoleEndedReason.Closing]: 'The company is closing',
  [RoleEndedReason.FullTime]: 'We hired the builder full-time',
  [RoleEndedReason.OtherTalent]:
    'We hired another person full-time for this role',
  [RoleEndedReason.Freeze]: 'Internal hiring freeze',
  [RoleEndedReason.Trial]: 'This was a trial, and the trial was complete',
  [RoleEndedReason.Builder]: 'The builder requested to end the contract',
};
