export * from './SetupIntent';

export interface PaymentMethod {
  id: PaymentMethodId;
  status: PaymentMethodStatus;
  type: PaymentMethodType;
  nickname?: string;
  preferable: boolean;
  card?: PaymentMethodCard;
  bankAccount?: PaymentMethodBankAccount;
}

export interface PaymentMethodCard {
  brand: PaymentMethodCardKnownBrand | string;
  brandName: string;
  last4: string;
  expMonth: number;
  expYear: number;
}

export type PaymentMethodCardKnownBrand =
  | 'AmEx'
  | 'Diners'
  | 'Discover'
  | 'JCB'
  | 'Mastercard'
  | 'UnionPay'
  | 'Visa';

export interface PaymentMethodBankAccount {
  bankName: string;
  countryName: string;
  last4: string;
}

export default PaymentMethod;

export type PaymentMethodId = string;

export enum PaymentMethodStatus {
  PENDING = 'PENDING',
  READY = 'READY',
}

export enum PaymentMethodType {
  ACH_DEBIT = 'ACH_DEBIT',
  CARD = 'CARD',
}
