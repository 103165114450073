import { ObjectID } from 'mongodb';
import { RoleCategoryId } from './RoleCategory';

export enum RegisterRequestType {
  SUPERCHARGE = 'SUPERCHARGE',
  BUILD_PRODUCT = 'BUILD_PRODUCT',
}

export enum HiringTimeline {
  Immediate = 'immediate',
  NextMonth = 'next_month',
  Within3Months = 'within_3_months',
  Exploring = 'exploring',
}

export interface ClientRegistration {
  emailVerified?: boolean;
  signupCompany?: ObjectID;
  companyQualified?: boolean;
  requestType?: RegisterRequestType;
  requestSolution?: string;
  requestRoles?: Array<RoleCategoryId>;
  requestHelp?: string;
  howDidYouHear?: string;
  hiringTimeline?: HiringTimeline;
}
